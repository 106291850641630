<template>
  <div
    v-if="isVisible"
    class="d-flex justify-space-between categories-filter"
    :class="{
      '-multi-rows': isTextAlwaysVisible
    }"
  >
    <BaseButton
      v-for="category in categories"
      :key="category.slug"
      text
      :class="{
        'child-active': selectedChildrenInCategories[category.slug],
        active: selectedCategories[category.slug]
      }"
      :disabled="isButtonsDisabled"
      class="flex-grow-1 pa-1 px-sm-1 pa-md-1 pa-lg-2 pa-xl-2 category-button"
      @click="selectCategory(category)"
    >
      <BaseIcon
        class="category-button-icon"
        :stripe-url="stripeUrl"
        :icon-name="category.icon"
      />
      <div class="text-center category-button-label">
        <span v-if="category.shortLabel" class="category-short-name">{{
          category.shortLabel
        }}</span>
        <span>{{ category.label }}</span>
      </div>
    </BaseButton>
  </div>
</template>

<script>
import urlUtils from '@shared/utils/url';
import debounce from 'lodash/debounce';
import publicationUtils from '@/services/utils/publicationUtils';
import AppStateEnum from '@/enums/AppStateEnum';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import CATEGORIES from '@/enums/CategoryFilterIconConfig';

import spriteReligions from '@/assets/images/svg-images/icons/religions/sprite-religions.svg';
import spriteCategories from '@/assets/images/svg-images/icons/categories/sprite-categories.svg';

const DEFAULT_LANGUAGE = 'en';

export default {
  name: 'CategoryFilter',
  components: { BaseButton, BaseIcon },
  props: {
    search: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const prevRoute = this.$store.state.ContextStore.previousRoute;
    const currentSearcher =
      this.search && prevRoute?.name === AppStateEnum.CATEGORY
        ? this.$store.getters['LibraryStore/getCategorySearcher']
        : this.$store.getters['LibraryStore/getLibrarySearcher'];

    const brand = this.$store.getters['ContextStore/brand'];
    const categories = CATEGORIES[brand].map(category => {
      return {
        label: publicationUtils.getCategoryLocalizationKey(
          category.name,
          this.$t.bind(this)
        ),
        shortLabel: category.shortName
          ? publicationUtils.getCategoryLocalizationKey(
              category.shortName,
              this.$t.bind(this)
            )
          : null,
        name: category.name,
        icon: category.icon,
        slug: this.normalizeCategoryName(category.name),
        to: {
          name: AppStateEnum.CATEGORY,
          params: { name: urlUtils.encodeStringToPath(category.name) }
        }
      };
    });
    let preSelectedCategories = [];
    let category;
    switch (this.$route.name) {
      case AppStateEnum.MANAGE_PUBLICATION:
      case AppStateEnum.SEARCH:
        preSelectedCategories = currentSearcher?.categories?.length
          ? currentSearcher.categories.map(c =>
              categories.find(cat => cat.slug === this.normalizeCategoryName(c))
            )
          : [];
        break;
      case AppStateEnum.CATEGORY:
        category = categories.find(c => c.slug === this.$route.params.name);
        preSelectedCategories = category ? [category] : [];
        break;
    }
    const initialSelected = preSelectedCategories.reduce((acc, c) => {
      if (c?.slug) {
        acc[c.slug] = c;
      }
      return acc;
    }, {});
    return {
      prevRoute,
      stripeUrl:
        brand === BrandsEnum.OCEAN ? spriteReligions : spriteCategories,
      isTextAlwaysVisible: brand === BrandsEnum.FFA,
      categories,
      endSubscription: null,
      hasPreSelectedCategories: !!preSelectedCategories.length,
      selectedCategories: initialSelected,
      initialSelectedCategories: initialSelected,
      selectedChildrenInCategories: {},
      debounceSearchCategories: debounce(this.searchCategories.bind(this), 500)
    };
  },
  computed: {
    categoriesByName() {
      return this.categories.reduce((acc, category) => {
        acc[this.normalizeCategoryName(category.slug)] = category;
        return acc;
      }, {});
    },
    isVisible() {
      const currentLang =
        AppStateEnum.SEARCH === this.$route.name
          ? this.$store.getters['SwContextStore/getLang']
          : this.$store.getters['ContextStore/currentLanguageGetter'];
      return DEFAULT_LANGUAGE === currentLang;
    },
    filterReady() {
      return this.$store.getters['SwFilterStore/getFilterReady'];
    },
    isButtonsDisabled() {
      return this.search && !this.filterReady;
    }
  },
  watch: {
    filterReady(val) {
      if (val) {
        this.setCategoriesDependingOnState();
      }
    },
    isVisible(newVal) {
      if (newVal) {
        this.selectedCategories = { ...this.initialSelectedCategories };
      }
    }
  },
  mounted() {
    if (!this.search) {
      return;
    }
    this.endSubscription = this.$store.subscribeAction(action => {
      if (
        action.type === 'SwFilterStore/toggleChecked' ||
        action.type === 'SwFilterStore/resetUserFilter'
      ) {
        this.$nextTick(this.setCategoriesFromSearchWidget);
      }
    });
    if (this.filterReady) {
      this.setCategoriesDependingOnState();
    }
  },
  destroyed() {
    if (this.search && this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    normalizeCategoryName(name) {
      return this.search
        ? this.$store.getters['SwContextStore/getNormalizedCategory'](name)
        : urlUtils.encodeStringToPath(name);
    },
    setCategoriesDependingOnState() {
      const searchFilter =
        this.$store.state.SwFilterStore?.filterByLang?.[DEFAULT_LANGUAGE] || {};
      if (
        this.prevRoute?.name === AppStateEnum.PRESENT_PUBLICATION &&
        searchFilter.hasChecked
      ) {
        this.setCategoriesFromSearchWidget();
      } else {
        this.setCategoriesFromLibrarySearcher();
        if (this.search && searchFilter.hasChecked) {
          this.setSelectedChildrenInCategoriesFromSearch();
        }
      }
    },
    setCategoriesFromLibrarySearcher() {
      if (this.hasPreSelectedCategories) {
        const normalizedCategories = Object.values(
          this.selectedCategories
        ).map(selectedCategory =>
          this.normalizeCategoryName(selectedCategory.name)
        );
        this.searchCategories(normalizedCategories);
      }
    },
    selectCategory(category) {
      if (this.selectedCategories[category.slug]) {
        delete this.selectedCategories[category.slug];
        this.selectedCategories = { ...this.selectedCategories };
      } else {
        this.selectedCategories = {
          ...this.selectedCategories,
          [category.slug]: category
        };
      }

      const selectedCategoriesArray = Object.values(this.selectedCategories);
      // apply the library filter for the search page
      if (this.search) {
        if (this.selectedChildrenInCategories.hasOwnProperty(category.slug)) {
          delete this.selectedChildrenInCategories[category.slug];
          this.selectedChildrenInCategories = {
            ...this.selectedChildrenInCategories
          };
        }

        const normalizedCategories = selectedCategoriesArray.map(
          selectedCategory => this.normalizeCategoryName(selectedCategory.name)
        );
        const searcher = this.$_getSearcher(
          this.$_getCategoriesOriginalNames()
        );
        this.$store.commit('LibraryStore/setLibrarySearcher', searcher);
        this.debounceSearchCategories(normalizedCategories);
        return;
      }

      // go to the category page if only one category is selected
      // or go to the category page if current category is not in the list
      if (
        (this.$route.name === AppStateEnum.MANAGE_PUBLICATION &&
          selectedCategoriesArray.length === 1) ||
        (this.$route.name === AppStateEnum.CATEGORY &&
          !this.hasPreSelectedCategories)
      ) {
        const categoryItem = this.categories.find(
          c => c.slug === selectedCategoriesArray[0].slug
        );
        this.$router.push(categoryItem.to);
        return;
      }

      // go to library page if a category is unselected on the category page
      if (
        this.$route.name === AppStateEnum.CATEGORY &&
        !selectedCategoriesArray.length
      ) {
        const searcher = this.$_getSearcher([]);
        this.$store.commit('LibraryStore/setLibrarySearcher', searcher);
        this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
        return;
      }

      // apply the library filter and go to the library page
      if (
        (this.$route.name === AppStateEnum.CATEGORY ||
          this.$route.name === AppStateEnum.MANAGE_PUBLICATION) &&
        selectedCategoriesArray.length > 1
      ) {
        const searcher = this.$_getSearcher(
          this.$_getCategoriesOriginalNames()
        );
        if (this.$route.name === AppStateEnum.CATEGORY) {
          this.$store.commit('LibraryStore/setLibrarySearcher', searcher);
          this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
        } else {
          this.$store.dispatch('LibraryStore/applyLibraryFilter', searcher);
        }
      }
    },
    searchCategories(categories) {
      return this.$store.dispatch(
        'SwFilterStore/performToggleCheckedAndSearch',
        {
          lang: DEFAULT_LANGUAGE,
          categories
        }
      );
    },
    setCategoriesFromSearchWidget() {
      const searchCategories = this.$store.getters[
        'SwFilterStore/getCategories'
      ];
      if (!searchCategories?.length) {
        return;
      }
      const selectedCategories = {};
      const selectedChildrenInCategories = {};
      for (const category of searchCategories) {
        if (!this.categoriesByName[category.name]) {
          continue;
        }
        if (category.checked) {
          selectedCategories[category.name] = this.categoriesByName[
            category.name
          ];
        }
        if (category.hasChecked) {
          selectedChildrenInCategories[category.name] = this.categoriesByName[
            category.name
          ];
        }
      }

      this.selectedCategories = selectedCategories;
      this.selectedChildrenInCategories = selectedChildrenInCategories;
      const searcher = this.$_getSearcher(this.$_getCategoriesOriginalNames());
      this.$store.commit('LibraryStore/setLibrarySearcher', searcher);
    },
    setSelectedChildrenInCategoriesFromSearch() {
      const searchCategories = this.$store.getters[
        'SwFilterStore/getCategories'
      ];
      if (!searchCategories?.length) {
        return;
      }
      const selectedChildrenInCategories = {};
      for (const category of searchCategories) {
        if (!this.categoriesByName[category.name]) {
          continue;
        }
        if (category.hasChecked) {
          selectedChildrenInCategories[category.name] = this.categoriesByName[
            category.name
          ];
        }
      }
      this.selectedChildrenInCategories = selectedChildrenInCategories;
    },
    $_getCategoriesOriginalNames() {
      const selectCategories = Object.values(this.selectedCategories).map(
        selectedCategory => {
          const cat = this.categories.find(
            categoryObj => categoryObj.slug === selectedCategory.slug
          );
          return cat.name;
        }
      );
      return selectCategories;
    },
    $_getSearcher(categories) {
      const builder = SearchPublicationsFactory.getSearcherBuilder();
      builder
        .setState(ManagePublicationsStates.LIBRARY)
        .setLanguage(this.$store.getters['ContextStore/currentLanguageGetter'])
        .setCategories(categories)
        .setFilter('');

      const searcher = builder.build();
      return searcher;
    }
  }
};
</script>

<style lang="less" src="./CategoryFilter.less"></style>
